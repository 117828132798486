import {PermissionCategories, PermissionMap} from "@lib/models/permissions.models";


export enum AuthCmsPermissions {

  // Users
  ReadUsers = 'ReadUsers',
  CreateUser = 'CreateUser',
  UpdateUser = 'UpdateUser',
  UpdateUserPassword = 'UpdateUserPassword',
  UpdateUserImage = 'UpdateUserImage',
  DisableUser = 'DisableUser',
  EnableUser = 'EnableUser',

  // Admins
  ReadAdmins = 'ReadAdmins',
  CreateAdmin = 'CreateAdmin',
  UpdateAdmin = 'UpdateAdmin',
  UpdateAdminPassword = 'UpdateAdminPassword',
  UpdateAdminImage = 'UpdateAdminImage',
  DisableAdmin = 'DisableAdmin',
  EnableAdmin = 'EnableAdmin',

  // Permissions
  ReadAuthRoles = 'ReadAuthRoles',
  CreateAuthRoles = 'CreateAuthRoles',
  UpdateAuthRoles = 'UpdateAuthRoles',
  DeleteAuthRoles = 'DeleteAuthRoles',

  // API Keys
  ReadApiKeys = 'ReadApiKeys',
  CreateApiKeys = 'CreateApiKeys',
  UpdateApiKeys = 'UpdateApiKeys',
  ArchiveApiKeys = 'ArchiveApiKeys',
}

export const authPermissionMap: PermissionMap<typeof AuthCmsPermissions> = {

  // Users
  ReadUsers: {
    name: 'Load User Data',
    description: 'Allows user to load a list of all users',
    category: PermissionCategories.Users
  },
  CreateUser: {
    name: 'Create new User',
    category: PermissionCategories.Users
  },
  UpdateUser: {
    name: 'Update User',
    category: PermissionCategories.Users
  },
  UpdateUserPassword: {
    name: 'Update User Password',
    category: PermissionCategories.Users
  },
  DisableUser: {
    name: 'Disable User',
    category: PermissionCategories.Users
  },
  UpdateUserImage: {
    name: 'Update User Profile Picture',
    category: PermissionCategories.Users
  },
  EnableUser: {
    name: 'Enable User',
    category: PermissionCategories.Users
  },

  // Admins
  ReadAdmins: {
    name: 'Load Admins',
    category: PermissionCategories.Users
  },
  CreateAdmin: {
    name: 'Create new Admin',
    category: PermissionCategories.Users
  },
  UpdateAdmin: {
    name: 'Update Admin',
    category: PermissionCategories.Users
  },
  UpdateAdminPassword: {
    name: 'Update Admin Password',
    category: PermissionCategories.Users
  },
  UpdateAdminImage: {
    name: 'Update Admin Profile picture',
    category: PermissionCategories.Users
  },
  DisableAdmin: {
    name: 'Disable Admin',
    category: PermissionCategories.Users
  },
  EnableAdmin: {
    name: 'Enable Admin',
    category: PermissionCategories.Users
  },

  // Permissions
  ReadAuthRoles: {
    name: 'Load Roles',
    category: PermissionCategories.Permissions
  },
  CreateAuthRoles: {
    name: 'Create new Role',
    category: PermissionCategories.Permissions
  },
  UpdateAuthRoles: {
    name: 'Update Role',
    category: PermissionCategories.Permissions
  },
  DeleteAuthRoles: {
    name: 'Delete Role',
    category: PermissionCategories.Permissions
  },

  // API Keys
  ReadApiKeys: {
    name: 'Load API Keys',
    category: PermissionCategories.ApiKeys
  },
  CreateApiKeys: {
    name: 'Create new API Keys',
    category: PermissionCategories.ApiKeys
  },
  UpdateApiKeys: {
    name: 'Update API Keys',
    category: PermissionCategories.ApiKeys
  },
  ArchiveApiKeys: {
    name: 'Archive API Keys',
    category: PermissionCategories.ApiKeys
  }
};
