<div class="wrapper" [@harmonica]="show()">

	<mat-form-field [appearance]="appearance()" [color]="color()"
                    [hideRequiredMarker]="hideRequired()" subscriptSizing="dynamic">

		<mat-label *ngIf="label()">
			{{label()}}
			<ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
					  matTooltipPosition="above" matTooltipTouchGestures="off"/>
		</mat-label>

		<input type="text" ngxInput [placeholder]="placeholder()" [required]="required()" [readonly]="readonly()"
               [(ngxModel)]="value" [autocomplete]="autocomplete()" [autofocus]="autofocus()" [showError]="showError()"
               #input [disabled]="disabled()" [attr.dir]="direction()" (touched)="markAsTouched()">

		<div matIconSuffix class="fix">
			<ng-content></ng-content>
		</div>

	</mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>
