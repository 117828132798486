<div #dialogPopup class="color-picker" (click)="$event.stopPropagation()">
  <div
    class="saturation-lightness"
    *ngIf="this.controls != 'only-alpha'"
    [slider]
    [rgX]="1"
    [rgY]="1"
    [style.background-color]="hueSliderColor"
    (newValue)="onSliderChange('saturation-lightness', $event)"
  >
    <div class="cursor" [style.top.px]="slider?.v" [style.left.px]="slider?.s">
      <div></div>
    </div>
  </div>

  <div class="hue-alpha box">
    <div class="left" *ngIf="this.controls != 'only-alpha'">
      <div class="selected-color-background"></div>
      <div
        class="selected-color"
        [style.background-color]="selectedColor"
      ></div>
    </div>

    <div class="right">
      <div
        *ngIf="this.controls != 'only-alpha'"
        #hueSlider
        class="hue"
        [slider]
        [rgX]="1"
        (newValue)="onSliderChange('hue', $event)"
      >
        <div class="sliderCursor" [style.left.px]="slider?.h">
          <div><div></div></div>
        </div>
      </div>

      <div
        *ngIf="this.controls != 'no-alpha'"
        #alphaSlider
        class="alpha"
        [slider]
        [rgX]="1"
        (newValue)="onSliderChange('alpha', $event)"
      >
        <div
          class="alpha-gradient"
          [ngStyle]="getBackgroundColor(alphaSliderColor)"
        ></div>
        <div class="sliderCursor" [style.left.px]="slider?.a">
          <div><div></div></div>
        </div>
      </div>
    </div>
  </div>
</div>
