import {PermissionCategories, PermissionMap} from "@lib/models/permissions.models";

export enum ArCmsPermissions {

  // AR App
  ReadArApps = 'ReadArApps',
  CreateArApp = 'CreateArApp',
  UpdateArApp = 'UpdateArApp',
  PublishArApp = 'PublishArApp',
  ArchiveArApp = 'ArchiveArApp',

  // Asset Bundles
  ReadAssetBundles = 'ReadAssetBundles',
  CreateAssetBundle = 'CreateAssetBundle',
  UpdateAssetBundle = 'UpdateAssetBundle',
  ArchiveAssetBundle = 'ArchiveAssetBundle',

  // AR Slugs
  ReadArSlugs = 'ReadArSlugs',
  CreateArSlug = 'CreateArSlug',
  UpdateArSlug = 'UpdateArSlug',
  DeleteArSlug = 'DeleteArSlug',

  // AR Codex
  ReadArCodices = 'ReadArCodices',
  CreateArCodex = 'CreateArCodex',
  UpdateArCodex = 'UpdateArCodex',
  ArchiveArCodex = 'ArchiveArCodex',
}

export const arPermissionMap: PermissionMap<typeof ArCmsPermissions> = {

  // Ar App Configs
  ReadArApps: {
    name: 'Load AR App Configs',
    description: 'Allows user to',
    category: PermissionCategories.ArApp
  },
  CreateArApp: {
    name: 'Create AR App Configs',
    description: 'Allows user to',
    category: PermissionCategories.ArApp
  },
  UpdateArApp: {
    name: 'Update AR App Configs',
    description: 'Allows user to',
    category: PermissionCategories.ArApp
  },
  PublishArApp: {
    name: 'Publish AR App Configs',
    description: 'Allows user to',
    category: PermissionCategories.ArApp
  },
  ArchiveArApp: {
    name: 'Archive AR App Configs',
    description: 'Allows user to',
    category: PermissionCategories.ArApp
  },

  // Asset Bundles
  ReadAssetBundles: {
    name: 'Load Asset Bundles',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },
  CreateAssetBundle: {
    name: 'Create new Asset Bundle',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },
  UpdateAssetBundle: {
    name: 'Update Asset Bundle',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },
  ArchiveAssetBundle: {
    name: 'Archive Asset Bundle',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },

  // AR Slugs
  ReadArSlugs: {
    name: 'Load AR Slugs',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },
  CreateArSlug: {
    name: 'Create AR Slugs',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },
  UpdateArSlug: {
    name: 'Update AR Slugs',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },
  DeleteArSlug: {
    name: 'Delete AR Slugs',
    description: 'Allows user to ',
    category: PermissionCategories.Ar
  },

  // AR Codex
  ReadArCodices: {
    name: 'Load AR Codices',
    description: 'Allows user to',
    category: PermissionCategories.ArCodex
  },
  CreateArCodex: {
    name: 'Create AR Codices',
    description: 'Allows user to',
    category: PermissionCategories.ArCodex
  },
  UpdateArCodex: {
    name: 'Update AR Codices',
    description: 'Allows user to',
    category: PermissionCategories.ArCodex
  },
  ArchiveArCodex: {
    name: 'Archive AR Codices',
    description: 'Allows user to',
    category: PermissionCategories.ArCodex
  }
}
