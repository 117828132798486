<div class="app-color-picker">
  <div class="preview">
    <div class="preview-background">
      <div
        class="circle"
        [class.colornull]="!color"
        [ngStyle]="{ background: color }"
      ></div>
    </div>
  </div>
</div>
