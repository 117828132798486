<div class="wrapper" [@harmonica]="show()">

    <mat-form-field [appearance]="appearance()" [color]="color()"
                    [hideRequiredMarker]="hideRequired()" subscriptSizing="dynamic">

        <mat-label *ngIf="label()">
            {{label()}}
            <ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
                      matTooltipPosition="above" matTooltipTouchGestures="off"/>
        </mat-label>

        <div class="color" matIconPrefix ngx-colors-trigger
             [colorPickerControls]="withAlpha() ? 'default' : 'no-alpha'" colorsAnimation="popup" [hideTextInput]="true"
             [(ngModel)]="value" format="hex" [disabled]="readonly()" [palette]="palette()"
             noClickBubble>
            <div class="fill" [style.background-color]="externalValue()"></div>
        </div>

        <input ngxInput type="text" [placeholder]="placeholder()" [required]="required()"
               [(ngxModel)]="value" (touched)="markAsTouched()"
               [autocomplete]="autocomplete()" [readonly]="readonly()" [autofocus]="autofocus()" #input
               [showError]="showError()" [attr.dir]="direction()">

        <div matIconSuffix class="fix">
            <ng-content/>
        </div>

    </mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>
