<div class="wrapper" [@harmonica]="show()">

	<mat-form-field [appearance]="appearance()" [color]="color()"
                    [hideRequiredMarker]="hideRequired()" subscriptSizing="dynamic">

		<mat-label *ngIf="label()">
			{{label()}}
			<ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
					  matTooltipPosition="above" matTooltipTouchGestures="off"/>
		</mat-label>

		<input ngxInput [type]="showPassword() ? 'text' : 'password'" [placeholder]="placeholder()"
               [(ngxModel)]="value" (touched)="markAsTouched()" [required]="required()"
		       [autocomplete]="autocomplete()" [readonly]="readonly()" [autofocus]="autofocus()"
               #input [showError]="showError()" [attr.dir]="direction()">

		<ngx-icon-button [alias]="showPassword() ? 'visible' : 'hidden'" matIconSuffix (click)="toggleShow($event)"
                         noClickBubble (mousedown)="$event.preventDefault()"/>

	</mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>
