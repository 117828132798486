import {PermissionCategories, PermissionMap} from "@lib/models/permissions.models";


export enum EmbeddedCmsPermissions {
  ReadEmbedLeaderboard = 'ReadEmbedLeaderboard',
  CreateEmbedLeaderboard = 'CreateEmbedLeaderboard',
  UpdateEmbedLeaderboard = 'UpdateEmbedLeaderboard',
  ArchiveEmbedLeaderboard = 'ArchiveEmbedLeaderboard',
  TestEmbedLeaderboard = 'TestEmbedLeaderboard',
  UploadEmbedLeaderboard = 'UploadEmbedLeaderboard',

  ReadEmbedAgendas = 'ReadEmbedAgendas',
  CreateEmbedAgenda = 'CreateEmbedAgenda',
  UpdateEmbedAgenda = 'UpdateEmbedAgenda',
  ArchiveEmbedAgenda = 'ArchiveEmbedAgenda',
  TestEmbedAgenda = 'TestEmbedAgenda',
  UploadEmbedAgenda = 'UploadEmbedAgenda',

  ReadEmbedRenderings = 'ReadEmbedRenderings',
  CreateEmbedRendering = 'CreateEmbedRendering',
  UpdateEmbedRendering = 'UpdateEmbedRendering',
  ArchiveEmbedRendering = 'ArchiveEmbedRendering',

  //Support
  ReadSupportScopes = 'ReadSupportScopes',
  CreateSupportScope = 'CreateSupportScope',
  UpdateSupportScope = 'UpdateSupportScope',
  ArchiveSupportScope = 'ArchiveSupportScope',

  //Moderator
  ReadModeratorConfigs = 'ReadModeratorConfigs',
  CreateModeratorConfig = 'CreateModeratorConfig',
  UpdateModeratorConfig = 'UpdateModeratorConfig',
  ArchiveModeratorConfig = 'ArchiveModeratorConfig',
}

export const embedPermissionMap: PermissionMap<typeof EmbeddedCmsPermissions> = {

  // Leaderboards
  ReadEmbedLeaderboard: {
    name: 'Load Embedded Leaderboards',
    description: 'Allows User to',
    category: PermissionCategories.Leaderboard,
  },
  CreateEmbedLeaderboard: {
    name: 'Create Embedded Leaderboards',
    description: 'Allows User to',
    category: PermissionCategories.Leaderboard,
  },
  UpdateEmbedLeaderboard: {
    name: 'Update Embedded Leaderboards',
    description: 'Allows User to',
    category: PermissionCategories.Leaderboard,
  },
  ArchiveEmbedLeaderboard: {
    name: 'Archive / Restore Embedded Leaderboards',
    description: 'Allows User to',
    category: PermissionCategories.Leaderboard,
  },
  TestEmbedLeaderboard: {
    name: 'Test Embedded Leaderboards',
    description: 'Allows User to',
    category: PermissionCategories.Leaderboard,
  },
  UploadEmbedLeaderboard: {
    name: 'Upload Embedded Leaderboards',
    description: 'Allows User to',
    category: PermissionCategories.Leaderboard,
  },

  // Agendas
  ReadEmbedAgendas: {
    name: 'Load Embedded Agendas',
    description: 'Allows User to',
    category: PermissionCategories.Agenda,
  },
  CreateEmbedAgenda: {
    name: 'Create Embedded Agendas',
    description: 'Allows User to',
    category: PermissionCategories.Agenda,
  },
  UpdateEmbedAgenda: {
    name: 'Update Embedded Agendas',
    description: 'Allows User to',
    category: PermissionCategories.Agenda,
  },
  ArchiveEmbedAgenda: {
    name: 'Archive / Restore Embedded Agendas',
    description: 'Allows User to',
    category: PermissionCategories.Agenda,
  },
  TestEmbedAgenda: {
    name: 'Test Embedded Agendas',
    description: 'Allows User to',
    category: PermissionCategories.Agenda,
  },
  UploadEmbedAgenda: {
    name: 'Upload Embedded Agendas',
    description: 'Allows User to',
    category: PermissionCategories.Agenda,
  },

  // Renderings
  ReadEmbedRenderings: {
    name: 'Read rendering configs',
    description: 'Allows User to',
    category: PermissionCategories.Rendering,
  },
  CreateEmbedRendering: {
    name: 'Create rendering configs',
    description: 'Allows User to',
    category: PermissionCategories.Rendering,
  },
  UpdateEmbedRendering: {
    name: 'Update rendering configs',
    description: 'Allows User to',
    category: PermissionCategories.Rendering,
  },
  ArchiveEmbedRendering: {
    name: 'Archive rendering configs',
    description: 'Allows User to',
    category: PermissionCategories.Rendering,
  },

  // Support
  ReadSupportScopes: {
    name: 'Load Support Data',
    category: PermissionCategories.Support,
  },
  CreateSupportScope: {
    name: 'Create Support Scopes / Folders',
    category: PermissionCategories.Support,
  },
  UpdateSupportScope: {
    name: 'Update Support Scopes / Folders',
    category: PermissionCategories.Support,
  },
  ArchiveSupportScope: {
    name: 'Archive Support Scopes / Folders',
    category: PermissionCategories.Support,
  },

  // Moderator
  ReadModeratorConfigs: {
    name: 'Load Moderator Configs',
    description: 'Allows User to',
    category: PermissionCategories.Moderator,
  },
  CreateModeratorConfig: {
    name: 'Create Moderator Configs',
    description: 'Allows User to',
    category: PermissionCategories.Moderator,
  },
  UpdateModeratorConfig: {
    name: 'Update Moderator Configs',
    description: 'Allows User to',
    category: PermissionCategories.Moderator,
  },
  ArchiveModeratorConfig: {
    name: 'Archive Moderator Configs',
    description: 'Allows User to',
    category: PermissionCategories.Moderator,
  },

}
