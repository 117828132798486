<div class="wrapper" [@harmonica]="show() && !hidden()">

    <mat-form-field appearance="fill" [hideRequiredMarker]="hideRequired()" [color]="color()" subscriptSizing="dynamic">

        <mat-label *ngIf="label()">
            {{label()}}
            <ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
                      matTooltipPosition="above" matTooltipTouchGestures="off"/>
        </mat-label>

        <mat-select [errorStateMatcher]="errorMatcher()" [(ngModel)]="value" [ngModelOptions]="{standalone: true}"
                    (openedChange)="onOpenStatus($event)"
                    [multiple]="false" [placeholder]="placeholder()" [required]="required()" [disabled]="readonly()">

            <mat-option *ngIf="clearable() && !required()">None</mat-option>

            @for (item of mappedItems(); track item.id) {
                <mat-option [value]="item.id">
                    {{item.name}}
                </mat-option>
            }

        </mat-select>

    </mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>
