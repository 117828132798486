<div class="wrapper" [@harmonica]="show()">

    <mat-form-field [appearance]="appearance()" [color]="color()"
                    [hideRequiredMarker]="hideRequired()" subscriptSizing="dynamic">

        <mat-label *ngIf="label()">
            {{label()}}
            <ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
                      matTooltipPosition="above" matTooltipTouchGestures="off"/>
        </mat-label>

        <ngx-icon-button matIconPrefix alias="date" noClickBubble (click)="openDatePicker()"/>

        <input type="text" ngxInput [placeholder]="placeholder()" [required]="required()"
               [readonly]="readonly()" [(ngxModel)]="textValue" (touched)="markAsTouched()"
               [autocomplete]="autocomplete()" [autofocus]="autofocus()" [showError]="showError()"
               #input [disabled]="disabled()" [attr.dir]="direction()">

    </mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>

<ngx-mat-timepicker [format]="timeFormat" (timeSet)="pickTime($event)"/>
