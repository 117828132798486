
<mat-form-field appearance="fill" [color]="color()" subscriptSizing="dynamic">

	<ngx-icon matIconPrefix class="search" alias="search"/>

	<input type="search" ngxInput [autofocus]="autofocus()" [placeholder]="placeholder()" [readonly]="readonly()"
           [(ngxModel)]="value" (touched)="markAsTouched()" #input [attr.dir]="direction()" [showError]="showError()">

	<ngx-icon-button alias="close" matIconSuffix (click)="clear()" [disabled]="!value?.length"
                     noClickBubble (mousedown)="$event.preventDefault()"/>

</mat-form-field>
