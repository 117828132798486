import {PermissionCategories, PermissionMap} from "@lib/models/permissions.models";

export enum ToolingCmsPermissions {
  ReadRequestLogs = 'ReadRequestLogs',
  ReadSwagger = 'ReadSwagger',
}

export const toolingPermissionMap: PermissionMap<typeof ToolingCmsPermissions> = {
  ReadRequestLogs: {
    name: "Read Request Logs",
    description: 'Allows user to',
    category: PermissionCategories.Diagnostics
  },
  ReadSwagger: {
    name: "Read Swagger Docs",
    description: 'Allows user to access the API documentation',
    category: PermissionCategories.Documentation
  }
};
