import {PermissionCategories, PermissionMap} from "@lib/models/permissions.models";

export enum ConnectCmsPermissions {

  ReadConnectServers = 'ReadConnectServers',
  ReadConnectServerSecrets = 'ReadConnectServerSecrets',
  CreateConnectServer = 'CreateConnectServer',
  UpdateConnectServer = 'UpdateConnectServer',
  UpdateConnectServerSecrets = 'UpdateConnectServerSecrets',
  ArchiveConnectServer = 'ArchiveConnectServer',
}

export const connectPermissionMap: PermissionMap<typeof ConnectCmsPermissions> = {
  ReadConnectServers: {
    name: 'Load Connect Servers',
    description: 'Allows User to',
    category: PermissionCategories.Connect,
  },
  ReadConnectServerSecrets: {
    name: 'Load Connect Server Secrets',
    description: 'Allows User to',
    category: PermissionCategories.Connect,
  },
  CreateConnectServer: {
    name: 'Add Connect Servers',
    description: 'Allows User to',
    category: PermissionCategories.Connect,
  },
  UpdateConnectServer: {
    name: 'Update Connect Servers',
    description: 'Allows User to',
    category: PermissionCategories.Connect,
  },
  UpdateConnectServerSecrets: {
    name: 'Update Connect Server Secrets',
    description: 'Allows User to',
    category: PermissionCategories.Connect,
  },
  ArchiveConnectServer: {
    name: 'Remove / Restore Connect Servers',
    description: 'Allows User to',
    category: PermissionCategories.Connect,
  },
}
