<button class="ngx-mat-timepicker-toggle mat-elevation-z0"
        color=""
        mat-icon-button
        (click)="open($event)"
        [disabled]="disabled"
        type="button">
    <svg xmlns="http://www.w3.org/2000/svg"
         class="ngx-mat-timepicker-toggle-default-icon"
         fill="currentColor"
         viewBox="0 0 24 24"
         width="24px"
         height="24px"
         *ngIf="!customIcon">
        <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003                   6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 6 L 11 12.414062 L 15.292969 16.707031 L 16.707031 15.292969 L 13 11.585938 L 13 6 L 11 6 z" />
    </svg>

    <ng-content select="[ngxMatTimepickerToggleIcon]"></ng-content>
</button>
