<div class="wrapper" [@harmonica]="show()">

    <mat-form-field [color]="color()" [appearance]="appearance()"
                    [hideRequiredMarker]="hideRequired()" subscriptSizing="dynamic">

        <mat-label *ngIf="label()">
            {{label()}}
            <ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
                      matTooltipPosition="above" matTooltipTouchGestures="off"/>
        </mat-label>

        <ngx-icon-button matIconPrefix alias="date" noClickBubble (click)="openDatePicker()"/>

        <input type="text" ngxInput [placeholder]="placeholder()" [required]="required()"
               [readonly]="readonly()" [(ngxModel)]="textValue" [autocomplete]="autocomplete()" [autofocus]="autofocus()"
               [showError]="showError()" #input [disabled]="disabled()" [attr.dir]="direction()" (touched)="markAsTouched()">

        <div matIconSuffix class="fix">
            <ng-content></ng-content>
        </div>

    </mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>
