<div class="wrapper" [@harmonica]="show()">

    <mat-form-field [appearance]="appearance()" [hideRequiredMarker]="hideRequired()" [color]="color()"
                    subscriptSizing="dynamic">

        <mat-label *ngIf="label()">
            {{ label() }}
            <ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
                      matTooltipPosition="above" matTooltipTouchGestures="off"/>
        </mat-label>

        <textarea type="text" ngxInput [placeholder]="placeholder()" [required]="required()" [readonly]="readonly()"
                  [(ngxModel)]="value" [autofocus]="autofocus()" [showError]="showError()" (touched)="markAsTouched()"
                  #input cdkTextareaAutosize [attr.dir]="direction()"></textarea>

        <div matIconSuffix class="fix">
            <ng-content></ng-content>
        </div>

    </mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>
