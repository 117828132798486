
<div class="wrapper" [@harmonica]="show()">

    <mat-form-field [appearance]="appearance()" [hideRequiredMarker]="hideRequired()" [color]="color()"
                    subscriptSizing="dynamic" matAutocompleteOrigin #container="matAutocompleteOrigin"
                    [floatLabel]="floatLabel()">

        <mat-label *ngIf="label()">
            {{label()}}
            <ngx-icon class="tooltip" alias="help" *ngIf="tooltip() as t" [matTooltip]="t"
                      matTooltipPosition="above" matTooltipTouchGestures="off"/>
        </mat-label>

        <div class="row">

            @for (tag of value; track tag) {
                <ngx-chip (removed)="removeTag(tag)"
                          noClickBubble (click)="$event.stopPropagation()" [canRemove]="!readonly()"
                          ngxDrag [dragData]="$index" [disableDrag]="!canReorder()"
                          ngxDropArea (ngxDrop)="onDrop($event, $index)" [dropPredicate]="getCanDrop($index)">
                    {{tag}}
                </ngx-chip>
            }

            @if (!readonly()) {
                <input type="text" ngxInput [placeholder]="placeholder()" [required]="required()"
                       [(ngxModel)]="query" (touched)="markAsTouched()"
                       [autofocus]="autofocus()" [showError]="showError()" #input
                       [disabled]="disabled()" [attr.dir]="direction()"
                       [matAutocomplete]="optionsTmp" [matAutocompleteConnectedTo]="container"
                       (keydown.backspace)="onBackspace($event)">
            }

        </div>

        <div matIconSuffix class="fix">
            <ng-content></ng-content>
        </div>

    </mat-form-field>

    <ngx-form-input-errors [canShow]="canShowError()" [errors]="errors()" [warnings]="warnings()"/>
</div>

<mat-autocomplete #optionsTmp [autoActiveFirstOption]="true" (optionSelected)="onSelected($event)">

    <mat-option *ngIf="options().match as match" [value]="match.id">{{match.name}}</mat-option>

    <mat-option *ngIf="options().query as query" [value]="query">
        <ngx-icon alias="add"/>
        <span>Add <i>{{query}}</i></span>
    </mat-option>

    @for (opt of options().options; track opt) {
        <mat-option [value]="opt.id">{{opt.name}}</mat-option>
    }
</mat-autocomplete>
