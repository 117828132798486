import {
  booleanAttribute, ChangeDetectionStrategy, Component, input, InputSignal, InputSignalWithTransform
} from '@angular/core';
import {BaseInputComponent, NgxInputDirective} from '@juulsgaard/ngx-forms';
import {harmonicaAnimation, IconDirective, NoClickBubbleDirective} from '@juulsgaard/ngx-tools';
import {AsyncPipe, NgIf} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatFormField, MatLabel, MatPrefix, MatSuffix} from "@angular/material/input";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgxColorsColor, NgxColorsModule} from "ngx-colors";
import {FormInputErrorsComponent} from "../../components";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'form-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  animations: [harmonicaAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NoClickBubbleDirective,
    NgIf,
    AsyncPipe,
    MatIconModule,
    IconDirective,
    MatFormField,
    MatLabel,
    MatPrefix,
    MatSuffix,
    MatTooltipModule,
    NgxColorsModule,
    FormInputErrorsComponent,
    NgxInputDirective,
    FormsModule
  ],
  standalone: true
})
export class ColorInputComponent extends BaseInputComponent<string, string|undefined> {

  readonly withAlpha: InputSignalWithTransform<boolean, unknown> = input(false, {transform: booleanAttribute});
  readonly palette: InputSignal<string[] | NgxColorsColor[]> = input<string[] | NgxColorsColor[]>(colors);

  constructor() {
    super();
  }

  postprocessValue(value: string|undefined): string|undefined {
    if (!value) return undefined;
    value = value.trim();

    if (value.search(/^#[\da-f]{6}$/i) >= 0) {
      return value.toUpperCase();
    }

    const alphaMatch = value.match(/^(#[\da-f]{6})[\da-f]{2}$/i);

    if (alphaMatch) {
      return this.withAlpha() ? alphaMatch[0]?.toUpperCase() : alphaMatch[1]?.toUpperCase();
    }

    return value;
  }

  preprocessValue(value: string|undefined): string|undefined {
    return value?.trim();
  }

}

const colors = [
  {
    color: "rojo", preview: "#E57373", variants:
      ["#FFEBEE", "#FFCDD2", "#EF9A9A", "#E57373", "#EF5350", "#F44336", "#E53935", "#D32F2F", "#C62828"]
  },
  {
    color: "rosa", preview: "#F06292", variants:
      ["#FCE4EC", "#F8BBD0", "#F48FB1", "#F06292", "#EC407A", "#E91E63", "#D81B60", "#C2185B", "#AD1457"]
  },
  {
    color: "purpura", preview: "#BA68C8", variants:
      ["#F3E5F5", "#E1BEE7", "#CE93D8", "#BA68C8", "#AB47BC", "#9C27B0", "#8E24AA", "#7B1FA2", "#6A1B9A"]
  },
  {
    color: "purpura oscuro", preview: "#9575CD", variants:
      ["#EDE7F6", "#D1C4E9", "#B39DDB", "#9575CD", "#7E57C2", "#673AB7", "#5E35B1", "#512DA8", "#4527A0"]
  },
  {
    color: "indigo", preview: "#7986CB", variants:
      ["#E8EAF6", "#C5CAE9", "#9FA8DA", "#7986CB", "#5C6BC0", "#3F51B5", "#3949AB", "#303F9F", "#283593"]
  },
  {
    color: "azul", preview: "#64B5F6", variants:
      ["#E3F2FD", "#BBDEFB", "#90CAF9", "#64B5F6", "#42A5F5", "#2196F3", "#1E88E5", "#1976D2", "#1565C0"]
  },
  {
    color: "celeste", preview: "#4FC3F7", variants:
      ["#E1F5FE", "#B3E5FC", "#81D4FA", "#4FC3F7", "#29B6F6", "#03A9F4", "#039BE5", "#0288D1", "#0277BD"]
  },
  {
    color: "cyan", preview: "#4DD0E1", variants:
      ["#E0F7FA", "#B2EBF2", "#80DEEA", "#4DD0E1", "#26C6DA", "#00BCD4", "#00ACC1", "#0097A7", "#00838F"]
  },
  {
    color: "color", preview: "#4DB6AC", variants:
      ["#E0F2F1", "#B2DFDB", "#80CBC4", "#4DB6AC", "#26A69A", "#009688", "#00897B", "#00796B", "#00695C"]
  },
  {
    color: "verde", preview: "#81C784", variants:
      ["#E8F5E9", "#C8E6C9", "#A5D6A7", "#81C784", "#66BB6A", "#4CAF50", "#43A047", "#388E3C", "#2E7D32"]
  },
  {
    color: "verde claro", preview: "#AED581", variants:
      ["#F1F8E9", "#DCEDC8", "#C5E1A5", "#AED581", "#9CCC65", "#8BC34A", "#7CB342", "#689F38", "#558B2F"]
  },
  {
    color: "lima", preview: "#DCE775", variants:
      ["#F9FBE7", "#F0F4C3", "#E6EE9C", "#DCE775", "#D4E157", "#CDDC39", "#C0CA33", "#AFB42B", "#9E9D24"]
  },
  {
    color: "amarillo", preview: "#FFF176", variants:
      ["#FFFDE7", "#FFF9C4", "#FFF59D", "#FFF176", "#FFEE58", "#FFEB3B", "#FDD835", "#FBC02D", "#F9A825"]
  },
  {
    color: "ambar", preview: "#FFD54F", variants:
      ["#FFF8E1", "#FFECB3", "#FFE082", "#FFD54F", "#FFCA28", "#FFC107", "#FFB300", "#FFA000", "#FF8F00"]
  },
  {
    color: "naranja", preview: "#FFB74D", variants:
      ["#FFF3E0", "#FFE0B2", "#FFCC80", "#FFB74D", "#FFA726", "#FF9800", "#FB8C00", "#F57C00", "#EF6C00"]
  },
  {
    color: "naranja oscuro", preview: "#FF8A65", variants:
      ["#FBE9E7", "#FFCCBC", "#FFAB91", "#FF8A65", "#FF7043", "#FF5722", "#F4511E", "#E64A19", "#D84315"]
  },
  {
    color: "marron", preview: "#A1887F", variants:
      ["#EFEBE9", "#D7CCC8", "#BCAAA4", "#A1887F", "#8D6E63", "#795548", "#6D4C41", "#5D4037", "#4E342E"]
  },
  {
    color: "escala de grises", preview: "#E0E0E0", variants:
      ["#FFFFFF", "#FAFAFA", "#F5F5F5", "#EEEEEE", "#E0E0E0", "#BDBDBD", "#9E9E9E", "#757575", "#616161", "#424242", "#000000"]
  },
  {
    color: "azul gris", preview: "#90A4AE", variants:
      ["#ECEFF1", "#CFD8DC", "#B0BEC5", "#90A4AE", "#78909C", "#607D8B", "#546E7A", "#455A64", "#37474F"]
  },
  {
    color: 'Consensus', preview: '#4957F5', variants:
      ['#4957F5', '#001AAB', '#4BBC92', '#F5C43D', '#F071A6']
  }
];
