import {SimpleObject} from "@juulsgaard/ts-tools";

export type PermissionMap<T extends SimpleObject> = { [key in keyof T]: PermissionInfoDefinition };

export enum PermissionType {
  Read = 'Read',
  Create = 'Create',
  Update = 'Update',
  Delete = 'Delete',
  Archive = 'Archive',
  Disable = 'Disable',
  Test = 'Test',
  Misc = ''
}


export enum PermissionCategories {
  Users = 'Users',
  Permissions = 'Permissions',
  ApiKeys = 'API Keys',
  Ar = 'AR',
  ArApp = 'AR App',
  Diagnostics = 'Diagnostics',
  Documentation = 'Documentation',
  Connect = 'Connect',
  Agenda = 'Agenda Applet',
  Rendering = 'Embedded Rendering',
  Leaderboard = 'Leaderboard Applet',
  Support = 'Support Applet',
  ArCodex = "AR Codex",
  Moderator = 'Moderator',
}

export interface PermissionInfoDefinition {
  name: string;
  category: PermissionCategories;
  description?: string;
  icon?: string;
}

export interface PermissionCategory {
  id: PermissionCategories;
  name: string;
  permissions?: PermissionInfoDefinition[];
}

export function getPermissionIcon(permission: string) {

  let type = PermissionType.Misc;

  for (let t of Object.values(PermissionType)) {
    if (!permission.startsWith(t)) continue;

    type = t;
    break;
  }

  switch (type) {
    case PermissionType.Read:
      return 'fal fa-cloud-download-alt';
    case PermissionType.Create:
      return 'fal fa-plus-circle';
    case PermissionType.Update:
      return 'fal fa-edit';
    case PermissionType.Delete:
    case PermissionType.Archive:
    case PermissionType.Disable:
      return 'fal fa-trash-alt';
    case PermissionType.Test:
      return 'fal fa-vial';
    case PermissionType.Misc:
      return 'fal fa-percentage';
  }
}

